<template>
  <a-spin :spinning="spinning">
    <div class="choose-seal">
      <div class="t-w">
        <step :s="3" />
      </div>

      <div class="sel-w">
        <div class="item-w">
          <div class="iw-l">选择签约公司:</div>
          <div class="iw-r">
            <a-select
              size="default"
              style="width: 100%"
              @change="orgChange"
              v-model="submitParams.organizationUnitId"
              placeholder="请选择"
              allowClear
              show-search
              :filter-option="filterOption"
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in orgsOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="item-w" v-if="standardType === '0'">
          <div class="iw-l">选择项目关联:</div>
          <div class="iw-r">
            <a-select
              size="default"
              style="width: 100%"
              @change="proChange"
              v-model="submitParams.projectId"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in projectOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>

      <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :actions-type="actionsType"
        :hide-row-selection="true"
        :hide-pagination="true"
        :columns="columns"
        :min-height="100"
      >
      </react-table>

      <div class="b-w">
        <a-button size="default" @click="goBack" style="margin-right: 10px">{{
          l("上一步")
        }}</a-button>
        <a-button size="default" type="primary" @click="_saveAgreement">
          {{ l("保存") }}
        </a-button>
      </div>

      <a-modal
        centered
        :title="inputModalParams.title"
        :visible="inputModalParams.visible"
        :confirm-loading="inputModalParams.confirmLoading"
        :cancelText="inputModalParams.cancelText"
        :okText="inputModalParams.okText"
        :maskClosable="inputModalParams.maskClosable"
        :destroyOnClose="inputModalParams.destroyOnClose"
        :width="inputModalParams.width"
        @ok="inputModalParams.confirm"
        @cancel="inputModalParams.cancel"
      >
        <a-form :form="form" v-bind="formItemLayout">
          <a-form-item label="印章">
            <a-select
              @change="modalSealChange"
              v-decorator="[
                'organizationSealId',
                { rules: [{ required: true, message: '请选择类型' }] },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) in sealOption"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import Step from "./step";
import ReactTable from "@/components/react-table";

export default {
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      tableData: [],
      totalItems: 0,
      columns: [],
      actionsType: {},
      submitParams: {
        agreementTemplateId: "",
        organizationUnitId: "",
        projectId: "",
        signMethodInfos: [],
      },
      smInfo: [],
      orgName: "",
      proName: "",
      sealName: "",
      orgsOption: [],
      projectOption: [],
      sealOption: [],
      standardType: null,
      // modal
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "请选择印章",
        cancelText: "取消",
        okText: "确认",
        form: {},
        id: null,
        sealOption: [], // { name: "客户", value: "Customer" }
        generalFieldsOfAgreementContentList: [],
        generalFieldsOfAgreementContentInitVal: [],
        switchDisabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.standardType = this.$route.params.standard;
    // this.initActionsType()
    this._agreementSigningMethodGetList();
    this._getOrgSelectList();
    this.initColumns();
  },
  methods: {
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: this.l("签署类型"),
          dataIndex: "signTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signTypeName" },
        },
        {
          title: this.l("名称"),
          dataIndex: "agreementTemplateName",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (_this.smInfo[index].signerType === "Organization") {
              return _this.orgName;
            } else {
              return "-";
            }
          },
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        {
          title: this.l("印章"),
          dataIndex: "isAutoSignDesc",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (_this.smInfo[index].signerType === "Organization") {
              let child = _this.$createElement("a", {
                domProps: {
                  innerHTML:
                    _this.smInfo && _this.smInfo[index].organizationSealId
                      ? _this.smInfo[index].organizationSealName
                      : "选择印章",
                },
                on: {
                  click: function () {
                    if (!_this.submitParams.organizationUnitId) {
                      _this.$notification["warning"]({
                        message: "提示",
                        description: "请选择签约公司",
                      });
                    }
                    if (
                      _this.standardType === "0" &&
                      !_this.submitParams.projectId
                    ) {
                      _this.$notification["warning"]({
                        message: "提示",
                        description: "请选择项目关联",
                      });
                    } else {
                      _this.selectSeal(record, index);
                    }
                  },
                },
                style: {},
                attrs: {},
              });
              let obj = {
                children: child,
                attrs: {},
                on: {},
              };
              return obj;
            } else {
              return "-";
            }
          },
          scopedSlots: { customRender: "isAutoSignDesc" },
        },
      ];
    },
    selectSeal(item, index) {
      let _this = this;
      // console.log(item, index)
      this._getOrgSealOption(this.submitParams.organizationUnitId);
      this.inputModalParams.visible = true;
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.smInfo[index].organizationSealId = values.organizationSealId;
            _this.smInfo[index].organizationSealName = _this.sealOption.filter(
              (v) => v.value === values.organizationSealId
            )[0].label;
            _this.initColumns();
            _this.inputModalParams.visible = false;
          }
        });
      };
    },
    orgChange(value) {
      this.submitParams.organizationUnitId = value;
      if (this.standardType === "0") {
        this.submitParams.projectId = "";
        this._getProSelectList(value);
      }
      // this.smInfo.map(item => {
      //   if (item.signerType === 'Organization') {
      //     item.organizationName = this.orgsOption.filter(v => v.value === value)[0].label
      //   }
      // })
      this.orgName = this.orgsOption.filter((v) => v.value === value)[0].label;
      this.initColumns();
    },
    proChange(value) {
      this.submitParams.projectId = value;
    },
    modalSealChange(value) {
      console.log(value);
    },
    async _getOrgSealOption(organizationUnitId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Organization/GetOrganizationrSealOption",
          params: { organizationUnitId: organizationUnitId, templateId: this.$route.params.id },
        });
        this.sealOption = res;
        this.inputModalParams.sealOption = res;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getProSelectList(orgId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProject/GetSelectList",
          params: { orgId: orgId },
        });
        this.projectOption = res.map((item) => {
          return {
            ...item,
            label: item.text,
          };
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getOrgSelectList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Organization/GetSelectList",
          params: {},
        });
        this.orgsOption = res.map((item) => {
          return {
            ...item,
            label: item.text,
          };
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _agreementSigningMethodGetList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementSigningMethod/GetList",
          params: { agreementTemplateId: this.$route.params.id },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTypeName:
              item.signerType === "Organization"
                ? "组织架构"
                : item.signerType === "Customer"
                ? "客户"
                : "个人",
          };
        });
        this.smInfo = res.map((item) => {
          return {
            signerType: item.signerType,
            // organizationName: null,
            organizationSealId: null,
            organizationSealName: null,
            isAutoSign: item.isAutoSign,
            signMethod: item.signMethod,
            sort: item.sort,
          };
        });
        this.totalItems = res.length;
        this.initSignerTypeList(this.tableData);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _saveAgreement() {
      // let smInfo = this.smInfo.filter(v => !v.organizationSealId && v.signerType === 'Organization')
      // if (smInfo.length) {
      //   this.$notification['warning']({
      //     message: '提示',
      //     description: `请选择印章`,
      //   })
      //   return
      // }
      this.spinning = true;
      this.submitParams.signMethodInfos = this.smInfo;
      this.submitParams.agreementTemplateId = this.$route.params.id;
      const opts = { ...this.submitParams };
      try {
        console.log({ ...this.submitParams });
        let res = await this.commonService.post({
          url:
            "/api/services/app/UnnaturalPersonProjectAgreement/SaveAgreement",
          params: { ...opts },
        });
        this.spinning = false;
        this.$notification["success"]({
          message: "",
          description: `保存成功`,
        });
        setTimeout(() => {
          this.$router.push({
            path: `/app/unnaturalperson/templeteManage`,
          });
        }, 1000);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    goBack() {
      this.$router.back();
    },
  },
  components: {
    ReactTable,
    Step,
  },
};
</script>


<style scoped lang="less">
@import "../../../styles/common.less";
.choose-seal {
  padding: 50px 100px;
  .t-w {
    background-color: @color-white;
  }
  .sel-w {
    background-color: @color-white;
    // width: 400px;
    .item-w {
      padding: 10px 0;
      #flex;
      #flex-vc;
      margin: 0 auto;
      width: 400px;
      .iw-l {
        #tac;
        padding-right: 5px;
        width: 30%;
        // font-size: 12px;
      }
      .iw-r {
        width: 70%;
      }
    }
  }

  .b-w {
    padding-top: 60px;
    #flex;
    #flex-hc;
    background-color: @color-white;
  }
}
</style>